import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

export default function DisconnectedDialog(props) {

    const { t } = useTranslation();

    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Verbindung unterbrochen"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('connection_lost')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClick} color={"secondary"}>
                    {t('relogin')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}