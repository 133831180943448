import React, {useEffect, useState, Suspense} from 'react';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import LightTheme from './assets/theme/LightTheme';
import DarkTheme from './assets/theme/DarkTheme';
import VoiceApp from './components/VoiceApp';
import Environment from './util/Environment';
import {Box} from '@mui/material';

function App() {

    const [theme, setTheme] = useState(LightTheme);

    async function switchTheme() {
        if (theme === LightTheme) {
            setTheme(DarkTheme);
        } else {
            setTheme(LightTheme);
        }

        if (Environment.isElectron() && window.darkMode && typeof window.darkMode.toggle === 'function') {
            try {
                await window.darkMode.toggle();
            } catch (error) {
                console.error('Error toggling dark mode:', error);
            }
        } else {
            console.error('window.darkMode or window.darkMode.toggle is not defined');
        }
    }

    function switchSystemTheme(isDarkTheme) {
        setTheme(isDarkTheme ? DarkTheme : LightTheme);
    }

    useEffect(() => {
        const mediaQueryListener = (e => {
            switchSystemTheme(e.matches);
        });

        const darkThemeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
        darkThemeMediaQuery.addEventListener('change', mediaQueryListener);

        const isDarkTheme = darkThemeMediaQuery.matches;
        switchSystemTheme(isDarkTheme);

        return () => darkThemeMediaQuery.removeEventListener('change', mediaQueryListener);
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Box>
                    <Suspense fallback={<div>Loading ...</div>}>
                        <VoiceApp switchTheme={switchTheme} theme={theme}/>
                    </Suspense>
                </Box>
            </ThemeProvider>
        </StyledEngineProvider>
    );

}

export default App;