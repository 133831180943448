import moment from 'moment';
import "moment/locale/de";

/*
 * @Author: Dennis Hooth (VoiceApp) 
 * @Date: 2020-10-03 17:45:25 
 * @Last Modified by: Dennis Hooth (VoiceApp)
 * @Last Modified time: 2020-10-29 20:24:13
 */
export default class DateUtil  {

    static formatPlayingTime = (milliSeconds) => {
        return milliSeconds ? moment(milliSeconds).format("mm:ss") : "-";
    }

    static formatSecondsPlayingTime = (milliSeconds) => {
        return milliSeconds ? moment(milliSeconds).format("ss") + "s" : "-";
    }

    static prettyFormat = (date, t) => {
        const locale = moment.locale(navigator.language);
        let acronym = undefined;

        if (this.isToday(date)) {
            acronym = t("today");
        }
        else if (this.isYesterday(date)) {
            acronym = t("yesterday");
        }

        const timeFormat = moment(date).locale(locale).format("LT");

        if (acronym) {
            return `${acronym} ${timeFormat}`;
        }

        return moment(date).locale(locale).format("lll");
    };

    static isToday = (date) => {
        return moment().isSame(date, 'day');
    }

    static isYesterday = (date) => {
        return moment().subtract(1, 'days').isSame(date, 'day');
    }

}
