import React, {Component} from 'react'
import HeaderBar from './headerBar/HeaderBar';
import ChatNavDrawer from './navigation/ChatNavDrawer';
import ChatArea from './chat/ChatArea';
import Login from './login/Login';
import DisconnectedDialog from './dialog/DisconnectedDialog';
import MessageService from '../service/mock/MessageService';
import ChatService from '../service/mock/ChatService';
import Environment from '../util/Environment';
import StompManager from '../util/StompManager';
import Type from '../model/Type';
import State from '../model/State';
import ProfileImage from '../util/ProfileImage';
import {sendNotification} from './notification/NotificationSender';
import { withTranslation } from 'react-i18next';

class VoiceApp extends Component {

    state = {
        open: false,
        chats: undefined,
        uuid: undefined,
        selectedChat: undefined,
        messages: [],
        webSocketConnected: false,
        page: 0,
        hasMoreMessages: false,
        maxNumberOfMessages: 20,
        scrollToMessageId: undefined,
        openDisconnectedDialog: false
    }

    // for mock.
    chatService = new ChatService();
    messageService = new MessageService();

    componentDidMount() {
        if (Environment.isMock()) {
            // set to true to call the VoiceApp component with mock data.
            let chats = this.chatService.findChats();
            this.setState({webSocketConnected: true, chats});
        }
    }

    // WebSocket connect callback
    onConnected = () => {
        const {uuid} = this.state;

        if (!Environment.isMock()) {
            // receive disconnect.
            StompManager.getInstance().subscribe('/topic/' + uuid + '/disconnect/web', this.onDisconnectReceived)
            // receive chats.
            StompManager.getInstance().subscribe('/topic/' + uuid + '/chat/web', this.onChatsReceived)
            // receive messages.
            StompManager.getInstance().subscribe('/topic/' + uuid + '/message/web', this.onMessagesReceived);
            // receive a new created message.
            StompManager.getInstance().subscribe('/topic/' + uuid + '/message/create/web', this.onMessageCreatedReceived);
            // receive updated message.
            StompManager.getInstance().subscribe('/topic/' + uuid + '/message/update/web', this.onMessageUpdatedReceive);
        }

        this.setState({webSocketConnected: true});
    };

    onDisconnectReceived = () => {
        StompManager.getInstance().disconnect();
        this.setState({openDisconnectedDialog: true});
    }

    onClickReLogin = () => {
        if (Environment.isMock()) {
            this.setState({selectedChat: undefined, openDisconnectedDialog: false})
        } else {
            // clear chats to show login component.
            this.setState({
                chats: undefined,
                openDisconnectedDialog: false,
                webSocketConnected: false,
                uuid: undefined
            });
        }
    }

    // WebSocket error callback
    onError = (errorData) => {
        console.error(errorData);
        this.setState({webSocketConnected: false});
    };

    // WebSocket callback
    onChatsReceived = (message) => {
        let content = message.body;
        let chats = JSON.parse(content);
        this.setState({chats});
    }

    // WebSocket callback
    onMessagesReceived = (message) => {
        const {selectedChat, messages, maxNumberOfMessages, page} = this.state;
        let newMessages = JSON.parse(message.body);
        // scroll to last new message.
        const scrollToMessageId = newMessages.length === 0 ? undefined : newMessages[newMessages.length - 1].id;
        let hasMoreMessages = newMessages.length === maxNumberOfMessages
        messages.splice(0, 0, ...newMessages);
        let newPageSize = page + 1;
        this.setState({selectedChat, messages, hasMoreMessages, scrollToMessageId, page: newPageSize});
    }

    // WebSocket callback
    onMessageCreatedReceived = (message) => {
        const {selectedChat, messages, chats} = this.state;
        let createdMessage = JSON.parse(message.body);

        if (selectedChat) {
            let selectedChatJid = selectedChat.jid;
            let jid = createdMessage.type === Type.chat ? createdMessage.isFromMe ? createdMessage.toJid : createdMessage.fromJid : createdMessage.toJid;

            // check if chat correct.
            if (selectedChatJid === jid) {
                messages.push(createdMessage);
                selectedChat.lastMessage = createdMessage;
                this.setState({selectedChat, messages, scrollToMessageId: createdMessage.id});
            }
        }

        if (createdMessage.state === State.unread) {
            let notificationChat = chats.find(chat => {
                let jid = createdMessage.type === Type.chat ? createdMessage.isFromMe ? createdMessage.toJid : createdMessage.fromJid : createdMessage.toJid;
                if (jid === chat.jid) {
                    return chat;
                }
                return null;
            });
            let profileImage = ProfileImage.getThumb(notificationChat);
            sendNotification(createdMessage.fromName, createdMessage.text, profileImage)
        }
    }

    // WebSocket callback
    onMessageUpdatedReceive = (message) => {
        // find message and update.
        const {messages} = this.state;
        let updatedMessage = JSON.parse(message.body);
        let index = messages.findIndex(m => m.id === updatedMessage.id);
        if (index >= 0) {
            messages[index] = updatedMessage;
            this.setState({messages, scrollToMessageId: updatedMessage.id});
        }
    }

    setOpen = (open) => {
        this.setState({
            open
        });
    }

    onChatClicked = (chat) => {
        const {selectedChat} = this.state;

        // same chat clicked.
        if (selectedChat && selectedChat.jid === chat.jid) {
            return
        }

        // clear messages by changing chat.
        this.setState({
            selectedChat: chat,
            messages: [],
            hasMoreMessages: false,
            page: 0,
            scrollToMessageId: undefined
        }, () => {
            this.onLoadMessages();
        });
    }

    onLoadMessages = () => {
        const {selectedChat, uuid, page, maxNumberOfMessages, messages} = this.state;

        if (selectedChat) {
            if (Environment.isMock()) {
                let newMessages = this.messageService.findMessages(selectedChat.jid, page, maxNumberOfMessages);
                let hasMoreMessages = newMessages.length === maxNumberOfMessages
                let scrollToMessageId = newMessages.length === 0 ? undefined : newMessages[newMessages.length - 1].id;
                let allMessages = [...messages];
                allMessages.splice(0, 0, ...newMessages);
                let newPageSize = page + 1;
                this.setState({messages: allMessages, hasMoreMessages, scrollToMessageId, page: newPageSize});
            } else {
                let requestJson = JSON.stringify({
                    jid: selectedChat.jid,
                    max: maxNumberOfMessages,
                    page: page,
                    type: selectedChat.type
                });
                StompManager.getInstance().send("/voiceapp/" + uuid + "/message/request/mobile", requestJson);
            }
        }
    }

    onSendMessage = (text) => {
        const {uuid, selectedChat} = this.state;
        if (Environment.isMock()) {
            let message = this.messageService.sendMessage(selectedChat.jid, text);
            let messages = this.state.messages;
            messages.push(message);
            // change last message in chat item.
            selectedChat.lastMessage = message
            this.setState({messages, selectedChat, scrollToMessageId: message.id});
        } else {
            let jsonMessage = JSON.stringify({toJid: selectedChat.jid, text: text, type: selectedChat.type});
            StompManager.getInstance().send("/voiceapp/" + uuid + "/message/send/mobile", jsonMessage);
        }
    }

    setUUID = (uuid) => {
        this.setState({uuid}, () => {
            // connect web client.
            StompManager.getInstance().connect(uuid, this.onConnected, this.onError);
        });
    }

    changeScrollToMessageId = (messageId) => {
        this.setState({scrollToMessageId: messageId});
    }

    render() {
        const {
            open,
            chats,
            selectedChat,
            uuid,
            messages,
            webSocketConnected,
            hasMoreMessages,
            scrollToMessageId,
            openDisconnectedDialog
        } = this.state;
        const { switchTheme, theme, t } = this.props;

        let title = undefined;
        let content = <Login setUUID={this.setUUID} uuid={uuid} webSocketConnected={webSocketConnected} theme={theme} />;
        if (chats) {
            title = t('chats')
            content =
                <React.Fragment>
                    <HeaderBar title={title} open={open} onOpen={() => this.setOpen(true)}
                               switchTheme={switchTheme} theme={theme}/>
                    <ChatNavDrawer open={open} onClose={() => this.setOpen(false)} selectedChat={selectedChat}
                                   chats={chats} onChatClicked={this.onChatClicked} uuid={uuid} theme={theme}/>
                    <ChatArea selectedChat={selectedChat} messages={messages} open={open}
                              onSendMessage={this.onSendMessage} onLoadMessages={this.onLoadMessages}
                              hasMoreMessages={hasMoreMessages} scrollToMessageId={scrollToMessageId}
                              changeScrollToMessageId={this.changeScrollToMessageId} theme={theme}/>
                    <DisconnectedDialog open={openDisconnectedDialog} onClick={this.onClickReLogin}/>
                </React.Fragment>
        }

        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        )
    }
}

export default withTranslation()(VoiceApp);