const TextBubbleStyles = theme => ({
    
    root: {
        paddingTop: '8px !important',
        paddingLeft: '12px !important',
        paddingBottom: '8px !important',
        paddingRight: '12px !important'
    },

    center: {
        textAlign: "center"
    },

    singleEmoji: {
        fontSize: 60,
    },

    multipleEmojis: {
        fontSize: 40,
    },

});

export default TextBubbleStyles;