import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enLang from "./locales/en/translation.json"
import deLang from "./locales/de/translation.json"

const initI18n = () => {
    return i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            fallbackLng: "en",
            supportedLngs: ["en", "de"],
            resources: {
                en: {
                    translation: enLang,
                },
                de: {
                    translation: deLang,
                },
            },
            detection: {
                order: ["navigator"],
            },
            react: {
                useSuspense: true,
            },
            lng: getBaseLanguage(navigator.language || 'en'),
        });
};

function getBaseLanguage(language) {
    return language.split('-')[0];
}

export { initI18n };