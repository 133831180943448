import React, { Component } from 'react';
import DocumentBubbleStyle from './DocumentBubbleStyle';
import { Grid, Typography, CardContent, Box } from '@mui/material';
import MimeType from '../../../util/MimeType';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import prettyBytes from 'pretty-bytes';
import TimeStampFooter from './TimeStampFooter';
import GroupNameHeader from './GroupNameHeader';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

class DocumentBubble extends Component {

    getDocumentImage = (mimeType) => {
        const imageName = MimeType.getImageName(mimeType);
        return require("../../../resources/icons/" + imageName);
    }

    openDocument = () => {
        const { onOpenSnackbar, t } = this.props;
        onOpenSnackbar(t('document.error'))
    }

    render() {
        const { message, classes, fromNameColor } = this.props;

        return (
            <React.Fragment>
                <CardContent className={classes.root}>
                    <Box mb={1}>
                        <GroupNameHeader message={message} fromNameColor={fromNameColor} />
                    </Box>
                    <Grid container>
                        <Grid item xs={3}>
                            <Avatar src={this.getDocumentImage(message.mimeType)} className={classes.documentImage} />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item xs={6}>
                                <Link underline="always" color="inherit" style={{ cursor: "pointer", fontSize: "18px" }} onClick={this.openDocument}>{message.fileName}</Link>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption">{prettyBytes(message.fileSize)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <TimeStampFooter message={message} />
                </CardContent>
            </React.Fragment>
        )
    }
}

export default withTranslation()(withStyles(DocumentBubbleStyle)(DocumentBubble));