import React, {Component} from 'react';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import ChatItem from './ChatItem';
import clsx from "clsx";
import ChatListStyle from './ChatListStyle';
import {withStyles} from '@mui/styles';
import {withTranslation} from 'react-i18next';

class ChatList extends Component {

    createChatList() {
        const {classes, selectedChat, chats, onChatClicked, open, t} = this.props;

        return (
            <List aria-label="main">
                {chats.length === 0 ? (
                    <Typography
                        variant="body1"
                        style={{
                            padding: "16px",
                            textAlign: "center",
                            maxWidth: open ? "100%" : "200px",
                            whiteSpace: open ? "normal" : "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                        }}
                        dangerouslySetInnerHTML={{
                            __html: open
                                ? t('no_chats')
                                : t('no_chats').replace('<br/>', ' ')
                        }}>
                    </Typography>
                ) : (
                    chats.map(chat => (
                        <React.Fragment key={chat.jid}>
                            {open ? (
                                <ChatItem chat={chat} selectedChat={selectedChat} onChatClicked={onChatClicked}/>
                            ) : (
                                <Tooltip
                                    title={
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                        >
                                            <Typography variant="body1" component="span">
                                                <Box fontWeight="fontWeightBold">
                                                    {chat.name}
                                                </Box>
                                            </Typography>
                                            <Typography variant="body1" component="span"
                                                        className={classes.lastMessageText}>
                                                {chat.lastMessage ? chat.lastMessage.text : null}
                                            </Typography>
                                        </Grid>
                                    }
                                    placement="right">
                                    <div>
                                        <ChatItem chat={chat} selectedChat={selectedChat}
                                                  onChatClicked={onChatClicked}/>
                                    </div>
                                </Tooltip>
                            )}
                        </React.Fragment>
                    ))
                )}
            </List>
        );
    }

    render() {
        const {classes, open} = this.props;

        return (
            <div
                className={clsx(classes.chatListContainer, {
                    [classes.chatListContainerOpen]: open,
                    [classes.chatListContainerClose]: !open,
                })}>

                {this.createChatList()}

            </div>
        );

    }
}

export default withTranslation()(withStyles(ChatListStyle)(ChatList));