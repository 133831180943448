import React, { Component } from 'react';
import ImageVideoBubbleStyle from './ImageVideoBubbleStyle';
import { CardMedia, Typography, CardContent } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import TimeStampFooter from './TimeStampFooter';
import MimeType from './../../../util/MimeType';
import GroupNameHeader from './GroupNameHeader';
import Environment from '../../../util/Environment';
import DateUtil from '../../../util/DateUtil';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

class ImageVideoBubble extends Component {

    getThumb = (thumb, fileName) => {
        if (thumb == null) {
            let tempFileName = fileName;
            if (!Environment.isMock()) {
                tempFileName = "thumb_preview.jpg";
            }
            return require("../../../resources/" + tempFileName);
        }
        return "data:image/jpeg;base64," + thumb;
    }

    open = () => {
        const { onOpenSnackbar, message, t } = this.props;
        let isImage = MimeType.isImage(message.mimeType);
        let errorMessage = t(isImage ? "image.error" : "video.error");
        onOpenSnackbar(errorMessage);
    }

    render() {
        const { message, classes, fromNameColor } = this.props;
        const thumb = message.thumb;
        const isImage = MimeType.isImage(message.mimeType);

        return (
            <React.Fragment>
                <div style={{ position: 'relative' }}>
                    <GroupNameHeader message={message} fromNameColor={fromNameColor} showBackground="true" />
                    <CardMedia
                        component="img"
                        image={this.getThumb(thumb, message.fileName)}
                        className={classes.image} onClick={this.open}>
                    </CardMedia>
                    {!isImage &&
                        <div className={classes.playIconContainer}>
                            <PlayCircleOutlineIcon className={classes.playIcon} onClick={this.open} />
                        </div>
                    }
                    <div className={classes.timeStampFooter}>
                        <TimeStampFooter message={message} />
                    </div>
                    {!isImage &&
                        <div className={classes.durationContainer}>
                            <VideocamIcon className={classes.durationIcon} />
                            <Typography variant="caption" component="span" className={classes.durationValue}>{DateUtil.formatSecondsPlayingTime(message.duration)}</Typography>
                        </div>
                    }
                </div>

                {message.text &&
                    <CardContent className={classes.comment} >
                        <Typography variant="body1" component="span">{message.text}</Typography>
                    </CardContent>
                }
            </React.Fragment>
        )
    }
}

export default withTranslation()(withStyles(ImageVideoBubbleStyle)(ImageVideoBubble));